<template>
  <div class="main-content">
    <div class="main-title">获奖证书</div>
    <div class="from">
      <div class="row line-border">
        <div class="row-box pr15 mb35">
          <div class="block-box w40">
            <div class="title">赛道</div>
            <div class="value">{{worksObj.raceObj.name}}</div>
          </div>
          <div class="block-box w60 pl15">
            <div class="title">作品编号</div>
            <div class="value">{{worksObj.work_no}}</div>
          </div>
          <div class="block-box">
            <div class="title">作品名称</div>
            <div class="value">{{worksObj.title}}</div>
          </div>
          <div class="block-box">
            <div class="title">作者</div>
            <div class="value">{{worksObj.userObj.realname}}</div>
          </div>
          <div class="block-box">
            <div class="title">邮寄地址</div>
            <div class="value">{{worksObj.userObj.address}}</div>
          </div>
        </div>
        <div class="row-box pr15">
          <div class="block-box">
            <div class="title">主题</div>
            <div class="value">{{worksObj.raceTopicObj.name}}</div>
          </div>
          <div class="block-box">
            <div class="title">证书编号</div>
            <input type="text" placeholder="请输入证书编号" v-model.trim="awardObj.certificate_no">
          </div>
          <div class="block-box" v-if="worksObj.race === '1'">
            <div class="title">指导老师</div>
            <div class="value">{{worksObj.teacher_realname1}}</div>
          </div>
          <div class="block-box">
            <div class="title">手机号码</div>
            <div class="value">{{worksObj.userObj.phone}}</div>
          </div>
        </div>
        <div class="row-box">
          <div class="block-box">
            <div class="title">奖项</div>
            <div class="value">
              <el-select v-model="prize" multiple placeholder="请选择">
                <el-option
                    class="sel"
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="block-box thumb-box">
            <div class="title">证书上传</div>
            <label for="def_input">
              <img :src="awardObj.certificate_preview || require('@/assets/upload_black_icon.png')" alt=" ">
            </label>
            <input type="file" accept="image/*" hidden id="def_input" @change="getFile">
          </div>
        </div>
      </div>
      <div class="row switch-box">
        <div class="title">是否网站展示</div>
        <el-switch
            v-model="show"
            active-color="#13ce66"
            inactive-color="#ff4949">
        </el-switch>
      </div>
      <div class="row cover-box ">
        <div class="title">获奖作品文章宣传图</div>
        <label for="def_input2">
          <img :src="awardObj.cover_img || require('@/assets/upload_black_icon.png')" alt=" ">
        </label>
        <input type="file" accept="image/*" hidden id="def_input2" @change="getFile2">
      </div>
      <div class="row text-box">
        <div class="title">获奖作品文章</div>
        <template>
          <vue-tinymce
              v-model="awardObj.text"
              :setting="setting" />
        </template>
      </div>

      <div class="btn-box">
        <span class="btn-confirm" @click="edit">确定</span>
      </div>
    </div>
  </div>
</template>

<script>
import {getWorksInfo,getWorkAwardInfo,editWorkAward} from "@/request/api";
import {Warning} from "@/plugins/warning";
import { LoadingFn } from '@/plugins/loading';
import $ from "jquery";
import OSS from "ali-oss";

export default {
  name: "work_award_edit",
  components:{

  },
  data() {
    return {
      id:0,
      cover_img:"",
      prize:[],
      worksObj:{},
      show:0,
      certificate_path:"",
      awardObj:{"certificate_path":"","certificate_no":"","text":"","prize":""},
      options:[
        {label:"一等奖",value:"1"},
        {label:"二等奖",value:"2"},
        {label:"三等奖",value:"3"},
        {label:"精彩卓越奖",value:"4"},
        {label:"十佳机构奖",value:"5"},
        {label:"十佳艺术指导奖",value:"6"},
        {label:"十大明日设计师奖",value:"7"},
        {label:"十大优秀导师",value:"8"},
        {label:"入围奖",value:"9"},
      ],
      setting: {
        menubar: true,
        toolbar: "undo redo | code | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |",
        toolbar_drawer: "sliding",
        quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
        plugins: "code link image media table lists fullscreen quickbars",
        language: 'zh_CN', //本地化设置
        language_url: '../../plugins/zh_CN.js',
        height: 600,
        images_upload_handler: (blobInfo, success, failure) => {

          //const that = this;
          const file = blobInfo.filename();
          let filename=file.replace(/.*(\/|\\)/, "");
          let fileExt=(/[.]/.exec(filename)) ? /[^.]+$/.exec(filename.toLowerCase()) : '';
          let storage = JSON.parse(window.localStorage.getItem('STORAGE'));
          $.ajax({
            url: process.env.VUE_APP_SERVER_URL+'/file/upload',
            data:{
              suffix:fileExt[0],
              path:"works"
            },
            headers:{
              Authorization:storage.infoModule.token
            },
            async: true,
            global: false,
            type: 'POST',
            dataType: 'json',
            timeout:1000*7,
            success: function(data) {
              if (data.status === 200) {
                const client = new OSS({
                  region: data.result.data.region,
                  accessKeyId: data.result.data.access_key_id,
                  accessKeySecret: data.result.data.access_key_secret,
                  stsToken: data.result.data.security_token,
                  bucket: data.result.data.bucket,
                });

                client.multipartUpload(data.result.data.key, blobInfo.blob(), {
                  // 设置上传回调。
                  progress:(p) => {
                    if(p>=1){
                      //console.log(data.result.data)
                      //that.thumb = data.result.data.url
                      //that.articleObj.thumb_img = data.result.data.full_url

                      success(data.result.data.full_url)
                    }
                  }
                });
              }else {
                failure("error");
              }
            }
          })

        }
      }
    }
  },
  computed: {

  },
  created() {
    this.id = this.$route.query.id;
    this.getInfo();
  },
  mounted() {

  },
  methods: {
    edit(){
      if(this.id == undefined){
        this.id = 0;
      }
      if(this.awardObj.text == null){
        this.awardObj.text = "";
      }
      let show = 0;
      if(this.show == true){
        show = 1;
      }
      const requestData = {
        "works_id":this.id,
        "certificate_no":this.awardObj.certificate_no,
        "certificate_path":this.certificate_path,
        "cover_img":this.cover_img,
        "text":this.awardObj.text,
        "prize":this.prize,
        "show":show,
      };
      editWorkAward(requestData).then((res)=>{
        console.log(res);
        Warning.success("操作成功");
      })

    },
    getInfo(){
      const requestData = {"id":this.id};
      getWorksInfo(requestData).then((res)=>{
        this.worksObj = res.data;
      })

      getWorkAwardInfo({"works_id":this.id}).then((res)=>{
        this.awardObj = res.data;
        this.prize = res.data.prizeList;
        console.log(res.data)
        if(res.data.show == 1){
          this.show = true;
        }else{
          this.show = false;
        }
      })
    },
    getFile(e){
      LoadingFn.open();
      const that = this;
      const file = e.target.value;
      let filename=file.replace(/.*(\/|\\)/, "");
      let fileExt=(/[.]/.exec(filename)) ? /[^.]+$/.exec(filename.toLowerCase()) : '';
      let storage = JSON.parse(window.localStorage.getItem('STORAGE'));
      $.ajax({
        url: process.env.VUE_APP_SERVER_URL+'/file/upload',
        data:{
          suffix:fileExt[0],
          path:"certificate"
        },
        headers:{
          Authorization:storage.infoModule.token
        },
        async: true,
        global: false,
        type: 'POST',
        dataType: 'json',
        timeout:1000*7,
        success: function(data) {
          if (data.status === 200) {
            const client = new OSS({
              region: data.result.data.region,
              accessKeyId: data.result.data.access_key_id,
              accessKeySecret: data.result.data.access_key_secret,
              stsToken: data.result.data.security_token,
              bucket: data.result.data.bucket,
            });

            client.multipartUpload(data.result.data.key, e.target.files[0], {
              // 设置上传回调。
              progress:(p) => {
                if(p>=1){
                  setTimeout(()=>{
                    that.certificate_path = data.result.data.url
                    that.awardObj.certificate_path = data.result.data.url
                    that.awardObj.certificate_preview = data.result.data.preview_url
                    LoadingFn.close();
                  },1000)
                }
              }
            });
          }else {
            Warning.open(data.msg);
          }
        }
      })
    },
    getFile2(e){
      LoadingFn.open();
      const that = this;
      const file = e.target.value;
      let filename=file.replace(/.*(\/|\\)/, "");
      let fileExt=(/[.]/.exec(filename)) ? /[^.]+$/.exec(filename.toLowerCase()) : '';
      let storage = JSON.parse(window.localStorage.getItem('STORAGE'));
      $.ajax({
        url: process.env.VUE_APP_SERVER_URL+'/file/upload',
        data:{
          suffix:fileExt[0],
          path:"works"
        },
        headers:{
          Authorization:storage.infoModule.token
        },
        async: true,
        global: false,
        type: 'POST',
        dataType: 'json',
        timeout:1000*7,
        success: function(data) {
          if (data.status === 200) {
            const client = new OSS({
              region: data.result.data.region,
              accessKeyId: data.result.data.access_key_id,
              accessKeySecret: data.result.data.access_key_secret,
              stsToken: data.result.data.security_token,
              bucket: data.result.data.bucket,
            });

            client.multipartUpload(data.result.data.key, e.target.files[0], {
              // 设置上传回调。
              progress:(p) => {
                if(p>=1){
                  setTimeout(()=>{
                    that.cover_img = data.result.data.url;
                    that.awardObj.cover_img = data.result.data.preview_url;
                    LoadingFn.close();
                  },1000)
                }
              }
            });
          }else {
            Warning.open(data.msg);
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.main-content{
  width: 100%;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 45px;
  .main-title{
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-left: 30px;
    margin-top: 30px;
  }
  .from{
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
    border-collapse:separate;
    border-spacing:0px 30px;
    position: relative;
    .row{
      width: 100%;
      display: flex;
      //justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      .row-box{
        width: 33%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        float: left;
        position: relative;
        .block-box{
          width: 100%;
          margin-top: 15px;
          & > input{
            height: 50px;
            width: 100%;
            border-radius: 25px 25px 25px 25px;
            opacity: 1;
            border: 2px solid #DDD1B4;
            padding-left: 15px;
            padding-right: 15px;
          }
        }
        .w30{
          width: 30%;
        }
        .w70{
          width: 70%;
        }
        .w40{
          width: 40%;
        }
        .w60{
          width: 60%;
        }
        .pl15{
          padding-left: 15px;
        }
        .pr15{
          padding-right: 15px;
        }
        .title{
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #000000;
          margin-bottom: 15px;
        }
        .value{
          height: 50px;
          width: 100%;
          display:flex;/*实现垂直居中*/
          align-items:center;/*实现水平居中*/
          border-radius: 25px 25px 25px 25px;
          opacity: 1;
          border: 2px solid #DDD1B4;
          padding-left: 15px;
          padding-right: 15px;
          margin-right: 30px;
          .el-select{
            width: 100%;
            height: 40px;
            border: 0px;
            .el-select__tags{
              max-height: 50px;
              border: 0;
            }
            .el-input__inner{
              border: 0;
            }
          }
        }
        .el-select{
          margin-right: 15px;
        }
      }
      .pl15{
        padding-left: 15px;
      }
      .pr15{
        padding-right: 15px;
      }
      .thumb-box{
        height: 290px;
        label {
          display: block;
          width: 100%;
          height: 100%;
          & > img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            cursor: pointer;
            margin-top: 15px;
            border: 0.5px solid $blockColor--1;
          }
        }
      }
    }
    .cover-box{
      height: 290px;
      width: 100%;
      .title{
        height: 40px;
        width: 100%;
        line-height: 40px;
        font-size: 18px;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        color: #000000;
        margin-bottom: 15px;
      }
      label {
        display: block;
        width: 300px;
        height: 200px;
        & > img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
          cursor: pointer;
          border: 0.5px solid $blockColor--1;
        }
      }
    }
    .text-box{
      .title{
        height: 40px;
        width: 100%;
        line-height: 40px;
        font-size: 18px;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        color: #000000;
        margin-bottom: 15px;
      }
      & > textarea{
        height: 200px;
        min-width: 500px;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        border: 2px solid #DDD1B4;
        padding: 15px;
      }
    }
    .switch-box{
      width: 100%;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      .title{
        height: 40px;
        width: 100%;
        line-height: 40px;
        font-size: 18px;
        font-family: PingFang HK-Regular, PingFang HK;
        font-weight: 400;
        color: #000000;
        margin-bottom: 15px;
      }
      .el-select{
        margin-right: 15px;
      }
    }
    .btn-box{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 40px;
      padding-left: 15px;
      padding-right: 15px;
      .btn-confirm{
        display: block;
        width: 100%;
        line-height: 48px;
        font-size: 21px;
        font-weight: 300;
        text-align: center;
        cursor: pointer;
        color: #fff;
        border-radius: 25px;
        background-color: #ba0704;
      }
    }
    .line-border{
      border-bottom: 0.5px solid $blockColor--1;
    }
    .mb35{
      margin-bottom: 35px;
    }
  }
}
</style>

<style>
.el-input__inner{
  border: 0;
}
</style>
